import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import RichTextEditor from "react-rte";
import Client from "../common/clients";

const PatientDiseaseHistory = ({ patientId }) => {
  const [antecedent, setAntecedent] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [lifeWay, setLifeWay] = useState(RichTextEditor.createEmptyValue());
  const [history, setHistory] = useState(RichTextEditor.createEmptyValue());
  const [weekInfo, setWeekInfo] = useState(RichTextEditor.createEmptyValue());
  const client = new Client();

  useEffect(() => {
      client.getPatientSynthesis(patientId).then((data) => {
            if (data.lifeWay) {
                setLifeWay(RichTextEditor.createValueFromString(data.lifeWay, 'html'));
            }

            if (data.history) {
                setHistory(RichTextEditor.createValueFromString(data.history, 'html'));
            }

          if (data.weekInfo) {
              setWeekInfo(RichTextEditor.createValueFromString(data.weekInfo, 'html'));
          }

          if (data.antecedent) {
              setAntecedent(RichTextEditor.createValueFromString(data.antecedent, 'html'));
          }
      });
  }, [])

  const submit = () => {
    client
      .createPatientSynthesis({
          lifeWay: lifeWay.toString("html").replace(/\n/gi, ""),
          antecedent: antecedent.toString("html").replace(/\n/gi, ""),
          history: history.toString("html").replace(/\n/gi, ""),
          weekInfo : weekInfo.toString("html").replace(/\n/gi, ""),
      }, patientId)
      .then((response) => {
        return response
      })
      .then(() => {
        alert("Synthése enregistrée");
      });
  };

  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        Antécédent
        <RichTextEditor
          editorStyle={{
            height: "250px",
          }}
          value={antecedent}
          onChange={(value) => setAntecedent(value)}
          toolbarConfig={{
            display: ["INLINE_STYLE_BUTTONS"],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12}>
        Mode de vie
        <RichTextEditor
          editorStyle={{
            height: "250px",
          }}
          value={lifeWay}
          onChange={(value) => setLifeWay(value)}
          toolbarConfig={{
            display: ["INLINE_STYLE_BUTTONS"],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12}>
        Histoire de la maladie
        <RichTextEditor
          editorStyle={{
            height: "250px",
          }}
          value={history}
          onChange={(value) => setHistory(value)}
          toolbarConfig={{
            display: ["INLINE_STYLE_BUTTONS"],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12}>
        Synthése de la semaine
        <RichTextEditor
          editorStyle={{
            height: "250px",
          }}
          value={weekInfo}
          onChange={(value) => setWeekInfo(value)}
          toolbarConfig={{
            display: ["INLINE_STYLE_BUTTONS"],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          onClick={submit}
          variant="contained"
          color="primary"
          style={{ margin: "center", paddingTop: "10px" }}
        >
          Valider
        </Button>
      </Grid>
    </Grid>
  );
};

export default PatientDiseaseHistory;
