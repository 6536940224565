import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
  header: {
    height: '40px',
    backgroundColor: 'inherit',
  },
  text: {
    color: 'black',
    textAlign: 'center',
    paddingTop: '10px'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: '3px'
  },
  inputText: {
    color: 'black'
  },
  container: {
    marginBottom: '15px',
    display: 'flex'
  },
  table: {
    minWidth: 700,
  },
  link: {
    color: 'white'
  },
  containerForm : {
    backgroundColor: 'inherit',
    display: 'flex',
    flexWrap: 'wrap',
    color: 'black'
  }
});

function createData(name, date) {
  return {name, date};
}

const rows = [
  createData('Fournisseur A',  '01/02/2019'),
  createData('Fournisseur B',  '01/02/2019'),
  createData('Fournisseur C', '01/02/2019'),
];

class ProviderList extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container className={classes.container}>
          <Grid item xs>
            <Paper className={classes.header}>
            <div className={classes.text}  variant="h4">
                Fournisseur
            </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Paper>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell align="right">Date d'ajout</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.date}</TableCell>
                      <TableCell align="right"><Link className={classes.link} to="/" ><EditIcon/></Link></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>            
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}


ProviderList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProviderList);
