import React, { Component } from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {BrowserRouter} from 'react-router-dom';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

const secureApp = () => (
  <MuiThemeProvider theme={theme}>
      <Layout theme={theme}/>
  </MuiThemeProvider>
)


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <PrivateRoute component={secureApp} />
      </BrowserRouter>
    );
  }
}

export default App;
