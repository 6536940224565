
import React, { useState } from 'react';
import { Table, TableRow, TableCell, TableBody, TableHead, TableFooter, TablePagination } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

const ListRow = ({data, title, indexEditable, handleChange, inputKey}) => (
        <TableRow>
          <TableCell scope="column">{title} </TableCell>
          {data.map((item, index) => {
              if (indexEditable !== index) {
                return <TableCell key={`${title}_${index}`} >{item}</TableCell>;
              }
              return <TableCell style={{backgroundColor: '#CBDCDC'}} key={`${title}_${index}`} >
                <input style={{width: '100%'}} type="text" name={`${inputKey}`} defaultValue={item} onChange={(e => handleChange(e.target.name, e.target.value))} />
              </TableCell>;
            })
          }
        </TableRow>
)

const ListAction = ({dateKeys, handleEdit, handleSubmit, indexEditable}) => (
  <TableRow>
  <TableCell scope="column"></TableCell>
    {dateKeys.map((item, index) => (<TableCell key={`action_date_${item}`}>
      <div style={{cursor: 'pointer'}} onClick={() => indexEditable === index ? handleSubmit(index) : handleEdit(index)}>
        { indexEditable === index ?  <SaveIcon/> : <EditIcon/>}
      </div>
    </TableCell>))}
  </TableRow>
)

const List = ({data, handleChangePage, handleUpdate}) => {

  const [page, setPage] = useState(0);
  const [indexEditable, setIndex] = useState(null);
  const [form, setForm] = useState({})
  
  const changePage = (event, page) => {
    setPage(page);
    handleChangePage(++page)
  }

  const handleEdit = (index) => {
    setIndex(index);
  }

  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  const handleSubmit = (index) => {
    const patientDayData = data.data;
    let baseData = {};
    for (var dataKey in patientDayData) {
      const currentItem = patientDayData[dataKey].filter((itemData, key) => {
        return key === index;
      })
      baseData = {...baseData, [dataKey]: currentItem[0]};
    }

    const currentDate = data['date_keys'].filter((item, key) => {
      return key === index;
    });

    const dataMerged = {...baseData, ...form};

    handleUpdate(dataMerged, currentDate[0], page);
    setIndex(null);
    setForm({});
  }

  if (typeof data['date_keys'] === 'undefined') {
    return <span></span>;
  }

  return (
      <Table>
        <TableHead>
        <TableRow>
          <TableCell>Mesure</TableCell>
          {
              data['date_keys'].map((item, index) => (<TableCell variant="head" padding="none" key={item}>{item}</TableCell>))
          }
          </TableRow>
        </TableHead>
        <TableBody>
          <ListAction dateKeys={data['date_keys']} handleEdit={handleEdit} indexEditable={indexEditable} handleSubmit={handleSubmit}/>
          <ListRow data={data['data']['weight']} title="Poids" indexEditable={indexEditable} handleChange={handleChange} inputKey="weight"/>
          <ListRow data={data['data']['tas']} title="TAS" indexEditable={indexEditable} handleChange={handleChange} inputKey="tas"/>
          <ListRow data={data['data']['tad']} title="TAD" indexEditable={indexEditable} handleChange={handleChange} inputKey="tad"/>
          <ListRow data={data['data']['pulse']} title="Pouls" indexEditable={indexEditable} handleChange={handleChange} inputKey="pulse"/>
          <ListRow data={data['data']['temperature']} title="Temperature" indexEditable={indexEditable} handleChange={handleChange} inputKey="temperature"/>
          <ListRow data={data['data']['sat']} title="Saturation" indexEditable={indexEditable} handleChange={handleChange} inputKey="sat"/>
          <ListRow data={data['data']['pain']} title="Douleur" indexEditable={indexEditable} handleChange={handleChange} inputKey="pain"/>
          <ListRow data={data['data']['nausea']} title="Nausée" indexEditable={indexEditable} handleChange={handleChange} inputKey="nausea"/>
          <ListRow data={data['data']['vomiting']} title="Vomissement" indexEditable={indexEditable} handleChange={handleChange} inputKey="vomiting"/>
          
        </TableBody>
        {indexEditable === null && <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[9]}
                  colSpan={data['date_keys'].length}
                  labelDisplayedRows={({ from, to, count }) => `Page ${from}-${count}`}
                  page={page}
                  count={data.nbPage}
                  rowsPerPage={1}
                  onChangePage={changePage}
                />
              </TableRow>
        </TableFooter>
        }
      </Table>
  );
}

export default List;
