import React from 'react'
import { withStyles, Grid, TextField, Button } from '@material-ui/core';

const styles = (theme) => ({
  inputText: {
    color: "black",
    width: '100%',
  },
  inputLabelText: {
    color: "black"
  },
  button: {
    color: "black"
  }
});

const ProviderForm = (props) => {
  const { classes } = props; 

  return (
    <div>
      Fournisseur
      <Grid container>
        <Grid item xs={12}>
          <form noValidate autoComplete="off" style={{paddingLeft: "20px"}}>
            <Grid item xs={6}>
              <TextField
                id="name"
                label="Nom"
                margin="normal"
                className={classes.inputText}
                InputLabelProps={{
                  shrink: true,
                  className: classes.inputLabelText
                }}
              />
            </Grid>
            <Grid item xs={6}>
                <TextField
                  id="address"
                  label="Adresse"
                  margin="normal"
                  className={classes.inputText}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.inputLabelText
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="cp"
                  label="Code postal"
                  margin="normal"
                  className={classes.inputText}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.inputLabelText
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="city"
                  label="Ville"
                  margin="normal"
                  className={classes.inputText}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.inputLabelText
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="outlined-button-file">
                  <Button variant="outlined" component="span" className={classes.button}>
                    Ajouter
                  </Button>
                </label>
              </Grid>
          </form>
        </Grid>
      </Grid>
      
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ProviderForm);
