import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField, TableFooter, TablePagination } from '@material-ui/core';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Client from '../common/clients';
import { Button } from '@material-ui/core';



const styles = theme => ({
  item : {
    marginBottom: '20px',
  },
  header: {
    height: '50px',
    textAlign: 'center',
    paddingTop: '20px'
  },
});


class PatientList extends Component {

  constructor(props) {
    super(props);
    this.client = new Client();

    this.state = {
      patients:[],
      allPatients: [],
      page: 0,
      search: ''
    }
  }

  componentWillMount() {
    this.client.getPatients().then((data) => {
      this.setState({
        patients: data.slice(0, 9),
        allPatients: data
      });
    });
  }

  setCurrentPage(newPage) {
      this.setState({
        patients: this.state.allPatients.slice(newPage * 9 +1 , newPage * 9 + 10),
        page: newPage
      }
    )
  }

  removePatient(id) {
    const {patients, allPatients} = this.state; 

    this.setState(
      {
        patients: patients.filter((item) => item.id !== id),
        allPatients: allPatients.filter((item) => item.id !== id)
      }
    )
  }

  render() {
    const { classes } = this.props;
    const { patients, allPatients, page, search } = this.state;

    return (
      <Grid container justify="center">
          <Grid className={classes.item} item xs={12}>
            <Paper className={classes.header}>
              <div className={classes.text}  variant="h4">
                  Patients
              </div>
            </Paper>
          </Grid>
          <Grid item xs={1}   className={classes.action}>
            <Button color="primary" variant="contained" component={Link} to="/patients/create">
              Ajouter
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{alignItems: 'center', justifyContent: 'center', marginBottom: '30px'}}>
              <Grid item xs={9}>
                <TextField
                  id="outlined-name"
                  label="Rechercher"
                  className={classes.textField}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!value) {
                        this.setState({
                          search: ''
                        });

                        this.setCurrentPage(this.state.page);

                        return;
                    }
                    const regex = `${value.toLowerCase()}`;
                    
                    const patientsFilter  =  allPatients.filter((patient) => {
                      return `${patient.name} ${patient.lastname}`.toLowerCase().match(new RegExp(regex, 'g'));
                    })

                    this.setState({
                      patients: patientsFilter,
                      search: value
                    })
                  }}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Paper>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell align="center">Nom</TableCell>
                    <TableCell align="center">Ville</TableCell>
                    <TableCell align="center">Catégorie</TableCell>
                    <TableCell align="right">Editer</TableCell>
                    <TableCell align="right">Archiver</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patients.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index+1}
                      </TableCell>
                      <TableCell align="center">{row.name} {row.lastname}</TableCell>
                      <TableCell align="center">{row.city}</TableCell>
                      <TableCell align="center">{row.category}</TableCell>
                      <TableCell align="right"><Link className={classes.link} to={`/patients/${row.id}`} ><EditIcon/></Link></TableCell>
                      <TableCell align="right" onClick={async () => { if(window.confirm('Etes vous sur de supprimer ce patient ?')){
                          const res = await this.client.archivedPatient(row.id);
                          if (res) {
                              this.removePatient(row.id);
                          }
                       } }}><DeleteIcon/></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {search === '' && <TableFooter>
                    <TableRow>
                      <TablePagination
                      rowsPerPageOptions={[9]}
                      colSpan={patients.length}
                      labelDisplayedRows={({ from, to, count }) => `Page ${from}-${count}`}
                      page={page}
                      count={Math.ceil(allPatients.length/15)}
                      rowsPerPage={1}
                      onChangePage={(event, newPage) => {
                        this.setCurrentPage(newPage)
                      }}
                      />
                    </TableRow>
                  </TableFooter>
                }
              </Table>            
            </Paper>
          </Grid>
      </Grid>
    )
  }
}


PatientList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PatientList);
