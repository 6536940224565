import config from "../config.json";

export default class Client {
  constructor() {
    this.url = config.api_url;
  }

  async doFetch(url, data, headers = {}) {
    const token = window.sessionStorage.getItem("token");
    const secureData = {
      ...data,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    };

    return fetch(url, secureData);
  }

  async getPatients() {
    try {
      const response = await this.doFetch(`${this.url}/api/patients`);
      return response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async createPatient(form) {
    const formData = new FormData(form);

    let jsonObject = {};

    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value;
    }

    try {
      return await this.doFetch(`${this.url}/api/patients`, {
        method: "POST",
        body: formData
      });
    } catch (err) {
      console.log(err);
    }
  }

  async udpatePatient(form, id) {
    const formData = new FormData(form);

    let jsonObject = {};

    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value;
    }

    try {
      return await this.doFetch(`${this.url}/api/patient/${id}`, {
        method: "POST",
        body: formData
      });
    } catch (err) {
      console.log(err);
    }
  }

  async getPatient(id) {
    try {
      const response = await this.doFetch(`${this.url}/api/patients/${id}`);
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async getPatientData(id, page = 1) {
    try {
      const response = await this.doFetch(
        `${this.url}/api/patient/data/${id}?page=${page}`
      );
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async getPatientSynthesis(id) {
    try {
      const response = await this.doFetch(
          `${this.url}/api/patient/synthesis/${id}`
      );
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async getPatientInstruction(id) {
    try {
      const response = await this.doFetch(
          `${this.url}/api/patient/instruction/${id}`
      );
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async getPatientTransmission(id) {
    try {
      const response = await this.doFetch(
        `${this.url}/api/patient/transmission/${id}`
      );
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async deletePatientTransmission(id) {
    try {
      const response = await this.doFetch(
        `${this.url}/api/patient/transmission/${id}`,
        { method: "DELETE" }
      );
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async getPatientExports(id) {
    try {
      const response = await this.doFetch(
        `${this.url}/api/patient/export/${id}`
      );
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async createPatientData(id, data) {
    try {
      let form = new FormData();
      form.append("patient_data[patient]", id);
      form.append("patient_data[type]", data.type);
      form.append("patient_data[value]", data.mesure);
      form.append("patient_data[createdAt]", data.date);

      const response = await this.doFetch(
        `${this.url}/api/patient/data/${id}`,
        {
          method: "POST",
          body: form
        }
      );

      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async createPatientSynthesis(data, patient) {
    try {
      let form = new FormData();
      form.append("patient_synthesis[lifeWay]", data.lifeWay);
      form.append("patient_synthesis[antecedent]", data.antecedent);
      form.append("patient_synthesis[weekInfo]", data.weekInfo);
      form.append("patient_synthesis[history]", data.history);

      const response = await this.doFetch(
          `${this.url}/api/patient/synthesis/${patient}`,
          {
            method: "POST",
            body: form
          }
      );

      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async createPatientInstruction(data, patient) {
    try {
      let form = new FormData();

      const getData  = (value) => {
        return value === null || value === 'null' ? '' : value;
      }

      form.append("patient_instruction[way]", getData(data.way));
      form.append("patient_instruction[injectionWay]", getData(data.injectionWay));
      form.append("patient_instruction[activity]", getData(data.activity));

      const response = await this.doFetch(
          `${this.url}/api/patient/instruction/${patient}`,
          {
            method: "POST",
            body: form
          }
      );

      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async createPatientTransmissionData(data) {
    try {
      let form = new FormData();
      form.append("patient_transmission[patient]", data.patient);
      form.append("patient_transmission[createdAt]", data.createdAt);
      form.append("patient_transmission[data]", data.data);
      form.append("patient_transmission[target]", data.target);
      form.append("patient_transmission[action]", data.action);
      form.append("patient_transmission[resultat]", data.resultat);

      const response = await this.doFetch(
        `${this.url}/api/patient/transmission/${data.patient}`,
        {
          method: "POST",
          body: form
        }
      );

      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async createPatientExport(id, data) {
    try {
      let form = new FormData();
      form.append("patient_export[patient]", id);
      form.append("patient_export[dateStart]", data.dateStart);
      form.append("patient_export[dateEnd]", data.dateEnd);
      form.append("patient_export[note]", data.note);
      form.append("patient_export[images]", data.images.join(","));

      const response = await this.doFetch(
        `${this.url}/api/patient/export/${id}`,
        {
          method: "POST",
          body: form
        }
      );

      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async login(username, password) {
    return await fetch(`${this.url}/api/login_check`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      }),
      body: JSON.stringify({ username, password })
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        return response;
      })
      .then(response => response.json())
      .catch(e => {
        return false;
      });
  }

  async checkToken() {
    const fetchSync = async () => {
      try {
        const response = await this.doFetch(`${this.url}/api/whoami`);
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        return true;
      } catch (e) {
        return false;
      }
    };

    return fetchSync();
  }

  async getParameter() {
    try {
      const response = await this.doFetch(`${this.url}/api/parameter`);
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async saveParameter(formData) {
    try {
      const response = await this.doFetch(`${this.url}/api/parameter`, {
        method: "POST",
        body: formData
      });

      return await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  async archivedPatient(id) {
    try {
      const response = await this.doFetch(
        `${this.url}/api/patient/archived/${id}`,
        { method: "PUT" }
      );

      await response.json();

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async updateDataPatient(id, date, formData) {
    try {
      const response = await this.doFetch(
        `${this.url}/api/patient/data/collection/update/${id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            date,
            data: formData
          })
        }
      );

      await response.json();

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}
