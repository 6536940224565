
export const ROLE_ADMIN = 'ROLE_ADMIN';

export function getUserRoles() {
  const storage = window.sessionStorage;

  if (!storage || !storage.getItem('roles')) {
    return;
  }

  return storage.getItem('roles');
}

export function removeCredentials() {
  const storage = window.sessionStorage;

  if (!storage ) {
    return;
  }

  return storage.clear();
}