import React, { useState } from 'react';
import moment from 'moment';
import { Grid, TextField, Select, Input, MenuItem, FormHelperText, Button } from '@material-ui/core';

const Form = ({handleSubmit}) => {

  const dateDefault = moment();

  const [mesure, setMesure] = useState('');
  const [date, setDate] = useState(dateDefault.format('Y-MM-DD'));
  const [type, setType]  = useState('weight');

  const submit = () => {
    handleSubmit({mesure, date, type});
  };

  return (
    <Grid container   direction="row" justify="center" alignItems="center" spacing={8}>
      <Grid item xs={4}>
      <TextField
        id="date"
        label="Date"
        type="datetime-local"
        onChange={e => setDate(e.target.value)}
        defaultValue={dateDefault.format('Y-MM-DD')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      </Grid>
      <Grid item xs={3}>
        <Select
          value={type}
          onChange={(e) => setType(e.target.value)}
          input={<Input name="type" id="type" />}
        >
          <MenuItem value="weight">
            <em>Poids</em>
          </MenuItem>
          <MenuItem value={'tas'}>TAS</MenuItem>
          <MenuItem value={'tad'}>TAD</MenuItem>
          <MenuItem value={'pulse'}>Pouls</MenuItem>
          <MenuItem value={'temperature'}>Temperature</MenuItem>
          <MenuItem value={'sat'}>Saturation</MenuItem>
          <MenuItem value={'pain'}>Douleur</MenuItem>
          <MenuItem value={'nausea'}>Nauseé</MenuItem>
          <MenuItem value={'vomiting'}>Vomissement</MenuItem>
        </Select>
        <FormHelperText>Mesure</FormHelperText>
      </Grid>
      <Grid item xs={3}>
        <TextField 
          id="value"
          label="Valeur"
          onChange={e => setMesure(e.target.value)}
          value={mesure}
          name="value"
        />
        <FormHelperText>Valueur</FormHelperText>
      </Grid>
      <Grid item xs={2}>
        <Button onClick={submit} variant="contained" color="primary" style={{margin: 'center', paddingTop: '10px'}}>Valider</Button>
      </Grid>
    </Grid>
  );
}

export default Form;