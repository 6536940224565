import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Input,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Client from "../common/clients";

const client = new Client();

const ImageTable = ({ images, handleRemove }) =>
  images.length > 0 &&
  images.map(elem => (
    <TableRow key={elem.id}>
      <TableCell>{elem.title}</TableCell>
      <TableCell>{elem.legend}</TableCell>
      <TableCell>
        <img
          src={`${client.url}/upload/${elem.image}`}
          alt={`image_${elem.title}`}
          width="300"
        />
      </TableCell>
      <TableCell>
        <DeleteIcon
          onClick={() => {
            if (window.confirm("Etes vous sure de supprimer la photo ?")) {
              return handleRemove(elem.id);
            }
          }}
        />
      </TableCell>
    </TableRow>
  ));

const PatientImage = ({ patientId }) => {
  const [title, setTitle] = useState("");
  const [legend, setLegend] = useState("");
  const [images, setImages] = useState([]);

  const formRef = useRef(null);

  useEffect(() => {
    client
      .doFetch(`${client.url}/api/patient_image/get/${patientId}`, {
        method: "GET"
      })
      .then(response => response.json())
      .then(data => {
        return data ? setImages(data) : null;
      })
      .catch(e => console.log(e));
  }, []);

  const handleChange = e => {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    client
      .doFetch(`${client.url}/api/patient_image/upload/${patientId}`, {
        method: "POST",
        body: formData
      })
      .then(response => (response.status === 200 ? response.json() : null))
      .then(data => {
        data && setImages(data);
      })
      .catch(e => console.log(e));

    e.target.value = null;
    setTitle("");
    setLegend("");
  };

  const handleRemove = id => {
    client
      .doFetch(`${client.url}/api/patient_image/remove/${id}`, {
        method: "DELETE"
      })
      .then(response => (response.status === 200 ? response.json() : null))
      .then(data => {
        data && setImages(data);
      })
      .catch(e => console.log(e));
  };

  return (
    <div>
      <h2>Ajouter une image</h2>
      <span>
        <i>
          Rappel: ne pas mettre d'image qui permet d'identifier une personne
          physique
        </i>
      </span>
      <form
        ref={formRef}
        encType="multipart/form-data"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <Input
          type="text"
          name="title"
          placeholder="Titre obligatoire"
          autoComplete="off"
          value={title}
          onChange={e => setTitle(e.target.value)}
          style={{ width: "250px", marginRight: "50px" }}
        />
        <Input
          type="text"
          name="legend"
          placeholder="Legend"
          autoComplete="off"
          value={legend}
          onChange={e => setLegend(e.target.value)}
          style={{ width: "250px", marginRight: "50px" }}
        />
        <input
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          name="file"
          style={{ display: "none" }}
          onChange={handleChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            Choisir une image
          </Button>
        </label>
      </form>
      {images.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Titre</TableCell>
              <TableCell>Legend</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ImageTable images={images} handleRemove={handleRemove} />
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default PatientImage;
