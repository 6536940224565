import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText, Collapse, List, withStyles } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import { Link } from "react-router-dom";


const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

function NavigationItem(props) {

  const { classes, addUrl, listUrl } = props;

  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <div>
      <ListItem button onClick={handleClick}>
              <ListItemIcon>{props.icon}</ListItemIcon>
              <ListItemText primary={props.label} />
              {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={listUrl}>
                <ListItem button className={classes.nested}>
                  <ListItemIcon><FormatListNumbered /></ListItemIcon>
                  <ListItemText inset primary="List" />
                </ListItem>
              </Link>
              <Link to={addUrl}>
                <ListItem button className={classes.nested}>
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText inset primary="Ajouter" />
                </ListItem>
              </Link>
            </List>
      </Collapse>
    </div>
  )
}

NavigationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(NavigationItem);

