import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import moment from "moment";
import Client from "../../common/clients";

const ImageOptions = ({ imagesData }) => {
  const options = imagesData.map(image => (
    <option key={`image_${image.id}`} value={image.id}>
      {image.title}
    </option>
  ));

  return imagesData.length > 0 && <>{options}</>;
};

const Form = ({ handleSubmit, patientId }) => {
  const dateDefault = moment();

  const [note, setNote] = useState("");
  const [dateStart, setDateStart] = useState(dateDefault.format("Y-MM-DD"));
  const [dateEnd, setDateEnd] = useState(dateDefault.format("Y-MM-DD"));
  const [images, setImages] = useState([]);
  const [ImagesData, setImagesData] = useState([]);

  const submit = () => {
    const client = new Client();

    client
      .createPatientExport(patientId, {
        note,
        dateEnd,
        dateStart,
        images
      })
      .then(data => handleSubmit(data));
  };

  useEffect(() => {
    const client = new Client();

    client
      .doFetch(`${client.url}/api/patient_image/get/${patientId}`, {
        method: "GET"
      })
      .then(response => response.json())
      .then(data => {
        return data ? setImagesData(data) : null;
      })
      .catch(e => console.log(e));
  }, []);

  return (
    <Grid container spacing={24}>
      <Grid item xs={3}>
        <TextField
          id="date"
          label="Date de debut"
          type="date"
          name="dateStart"
          onChange={e => setDateStart(e.target.value)}
          value={dateStart}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="date"
          label="Date de fin"
          type="date"
          name="dateEnd"
          onChange={e => setDateEnd(e.target.value)}
          value={dateEnd}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="note"
          label="Note"
          onChange={e => setNote(e.target.value)}
          value={note}
          name="note"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          onClick={submit}
          variant="contained"
          color="primary"
          style={{ margin: "center", paddingTop: "10px" }}
        >
          Valider
        </Button>
      </Grid>
      {ImagesData.length > 0 && (
        <Grid item xs={3}>
          <label
            style={{
              verticalAlign: "middle",
              display: "block",
              width: "50px",
              marginBottom: "5px"
            }}
          >
            {" "}
            Images{" "}
          </label>
          <select
            value={images}
            name="images"
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              width: "200px"
            }}
            onChange={e => {
              const { options } = e.target;
              const value = [];
              for (let i = 0, l = options.length; i < l; i += 1) {
                if (options[i].selected) {
                  value.push(options[i].value);
                }
              }

              setImages(value);
            }}
            multiple
          >
            <ImageOptions imagesData={ImagesData} />
          </select>
        </Grid>
      )}
    </Grid>
  );
};

export default Form;
