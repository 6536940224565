import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import RichTextEditor from "react-rte";
import moment from "moment";
import Client from "../../common/clients";

const Form = ({ handleSubmit, patientId }) => {
  const dateDefault = moment();

  const [date, setDate] = useState(dateDefault.format());
  const [target, setTarget] = useState("");
  const [action, setAction] = useState(RichTextEditor.createEmptyValue());
  const [data, setData] = useState(RichTextEditor.createEmptyValue());
  const [resultat, setResultat] = useState(RichTextEditor.createEmptyValue());

  const submit = () => {
    const client = new Client();

    client
      .createPatientTransmissionData({
        createdAt: date,
        target,
        action: action.toString("html").replace(/\n/gi, ""),
        data: data.toString("html").replace(/\n/gi, ""),
        resultat: resultat.toString("html").replace(/\n/gi, ""),
        patient: patientId,
      })
      .then((response) => {
        handleSubmit(response);
      })
      .then(() => {
        alert("Transmission enregistrée");
        setDate(dateDefault.format());
        setTarget("");
        setAction(RichTextEditor.createEmptyValue());
        setData(RichTextEditor.createEmptyValue());
        setResultat(RichTextEditor.createEmptyValue());
      });
  };

  return (
    <Grid container spacing={24}>
      <Grid item xs={3}>
        <TextField
          id="date"
          label="Date"
          type="datetime-local"
          name="createdAt"
          onChange={(e) => setDate(e.target.value)}
          defaultValue={dateDefault.format("Y-MM-DD")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="target"
          label="Cibles"
          onChange={(e) => setTarget(e.target.value)}
          value={target}
          name="target"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        Donnée
        <RichTextEditor
          editorStyle={{
            height: "250px",
          }}
          value={data}
          onChange={(value) => setData(value)}
          toolbarConfig={{
            display: ["INLINE_STYLE_BUTTONS"],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12}>
        Actions
        <RichTextEditor
          editorStyle={{
            height: "250px",
          }}
          value={action}
          onChange={(value) => setAction(value)}
          toolbarConfig={{
            display: ["INLINE_STYLE_BUTTONS"],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12}>
        Resultats
        <RichTextEditor
          editorStyle={{
            height: "250px",
          }}
          value={resultat}
          onChange={(value) => setResultat(value)}
          toolbarConfig={{
            display: ["INLINE_STYLE_BUTTONS"],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          onClick={submit}
          variant="contained"
          color="primary"
          style={{ margin: "center", paddingTop: "10px" }}
        >
          Valider
        </Button>
      </Grid>
    </Grid>
  );
};

export default Form;
