import React, {useState, useEffect} from 'react';
import {Route} from 'react-router-dom';
import Login from './Login';
import Client from '../common/clients';

const checkCredential = async (setter) => {
  const storage = window.sessionStorage;
  const token = storage.getItem('token');

  if (!token) {
    setter(false);

    return;
  }

  const client = new Client();
  const response = await client.checkToken();
  return setter(response);
}

export default ({ component: Component, ...rest }) => {
const [resolve, setResolve] = useState(null);

  useEffect( () => {
    checkCredential(setResolve);
  });

  if (resolve === null) {
    return null;
  }

  return <Route {...rest} render={(props) => {
    return resolve === true
      ? <Component {...props} />
      : <Login {...props} />
    }}
  />;
}
