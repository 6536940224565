import React, { useState } from "react";
import {
  Grid,
  TextField,
  withStyles,
  Button,
  Select,
  MenuItem,
  Input,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import Client from "../common/clients";
import moment from "moment";

const styles = (theme) => ({});

const PatientForm = (props) => {
  const { classes, history, patient } = props;
  let createForm = null;

  const date = moment(patient && patient.dateStart);
  const dateBithday =
    patient && patient.birthday ? moment(patient.birthday) : null;

  const [name, setName] = useState(patient && patient.name);
  const [lastname, setLastName] = useState(patient && patient.lastname);
  const [birthday, setBithday] = useState(
    dateBithday ? dateBithday.format("Y-MM-DD") : ""
  );
  const [dateStart, setDateStart] = useState(date.format("Y-MM-DD"));
  const [weight, setWeight] = useState(patient && patient.weight);
  const [height, setHeight] = useState(patient && patient.height);
  const [target, setTarget] = useState(patient && patient.target);
  const [imc, setImc] = useState(patient && patient.imc);
  const [city, setCity] = useState(patient && patient.city);
  const [category, setCategory] = useState(
    (patient && patient.category) || "NONE"
  );

  const handleSubmit = () => {
    const client = new Client();
    if (!patient) {
      client.createPatient(createForm).then(() => history.push("/patients"));
    } else {
      client
        .udpatePatient(createForm, patient.id)
        .then(() => alert("Modification enregistré"));
    }
  };

  return (
    <Grid container>
      {!patient && "Ajouter un Patient"}
      <Grid container>
        <Grid item xs={12}>
          <form
            ref={(el) => (createForm = el)}
            noValidate
            autoComplete="off"
            style={{ paddingLeft: "20px", paddingTop: "20px" }}
          >
            <Grid item xs={6}>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                input={<Input name="patient[category]" id="category" />}
                displayEmpty
              >
                <MenuItem value="NONE" disabled>
                  Choisir une catégorie
                </MenuItem>
                <MenuItem value="IV">IV</MenuItem>
                <MenuItem value="NEAD">NEAD</MenuItem>
                <MenuItem value="DIVERS">DIVERS</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="name"
                label="Nom"
                name="patient[name]"
                margin="normal"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastname"
                label="Prenom"
                name="patient[lastname]"
                margin="normal"
                onChange={(e) => setLastName(e.target.value)}
                value={lastname}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="city"
                label="Ville"
                name="patient[city]"
                margin="normal"
                onChange={(e) => setCity(e.target.value)}
                value={city}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="birthday"
                label="Date de naissance"
                type="date"
                name="patient[birthday]"
                margin="normal"
                onChange={(e) => setBithday(e.target.value)}
                value={birthday}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="weight"
                label="Poids"
                name="patient[weight]"
                margin="normal"
                onChange={(e) => setWeight(e.target.value)}
                value={weight || ""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="height"
                label="Taille"
                name="patient[height]"
                margin="normal"
                onChange={(e) => setHeight(e.target.value)}
                value={height || ""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="imc"
                label="IMC"
                name="patient[imc]"
                margin="normal"
                onChange={(e) => setImc(e.target.value)}
                value={imc || ""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="dateStart"
                label="Date de prise en charge"
                type="date"
                name="patient[dateStart]"
                margin="normal"
                onChange={(e) => setDateStart(e.target.value)}
                value={dateStart}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-multiline-flexible"
                label="Motif de prise en charge"
                multiline
                rowsMax="9"
                name="patient[target]"
                className={classes.inputText}
                margin="normal"
                variant="outlined"
                onChange={(e) => setTarget(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                  className: classes.inputLabelText,
                }}
                value={target}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="outlined-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={handleSubmit}
                  className={classes.button}
                >
                  {!patient ? "Ajouter un Patient" : "Modifier"}
                </Button>
              </label>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(PatientForm);
