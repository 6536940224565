import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import Client from '../common/clients';
import Form from './PatientDataForm';
import List from './PatientDataList';

export default ({patientId, type, unit}) => {
  const [patientData, setPatientData] = useState([]);

  const client = new Client();

  useEffect(() => {
      client.getPatientData(patientId).then((data) => setPatientData(data));
  }, []);

  const handleSubmit = data => {
      client.createPatientData(patientId, data).then(response => 
        client.getPatientData(patientId).then((data) => setPatientData(data))
      );
  }

  const handleChangePage = (page) => {
    client.getPatientData(patientId, page).then((data) => setPatientData(data));
  }

  const handleUpdate = (form, date, page) => {
    client.updateDataPatient(patientId, date, form).then(() => {
      client.getPatientData(patientId, page || 1).then((data) => setPatientData(data));
    })
  }

  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} style={{marginBottom: '50px'}}>
          <Form handleSubmit={handleSubmit}/>
        </Grid>
        <Grid item xs={12}>
          <List data={patientData} handleChangePage={handleChangePage} handleUpdate={handleUpdate}/>
        </Grid>
      </Grid>
    </div>
  )
}
