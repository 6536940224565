import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core';
import Form from './Form';
import List from './List';
import Client from '../../common/clients';

export default ({patientId}) => {

  const [exports, setExports] = useState([]);
  
  useEffect(() => {
    const client = new Client();
    client.getPatientExports(patientId).then(data => setExports(data));
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} style={{marginBottom: '100px'}}>
        <Form patientId={patientId} handleSubmit={(data) => setExports([data, ...exports])}/>
      </Grid>
      <Grid item xs={12}>
        <List data={exports} />
      </Grid>
    </Grid>
  )
}
