import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const List = ({ data, handleDelete }) => {
  if (!data) {
    return <span></span>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Cibles</TableCell>
          <TableCell>Données</TableCell>
          <TableCell>Actions</TableCell>
          <TableCell>Resultats</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <TableRow key={`${item.id}_row`}>
            <TableCell key={`${item.id}_date`}>{item.createdAt}</TableCell>
            <TableCell key={`${item.id}_target`}>{item.target}</TableCell>
            <TableCell
              key={`${item.id}_data`}
              dangerouslySetInnerHTML={{ __html: item.data }}
            />
            <TableCell
              key={`${item.id}_action`}
              dangerouslySetInnerHTML={{ __html: item.action }}
            />
            <TableCell
              key={`${item.id}_resultat`}
              dangerouslySetInnerHTML={{ __html: item.resultat }}
            />
            <TableCell key={`${item.id}_delete`}>
              <IconButton
                aria-label="Delete"
                onClick={() => {
                  handleDelete(item.id);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default List;
