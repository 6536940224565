import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/Home';
import Person from '@material-ui/icons/Person';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import FolderSpecial from '@material-ui/icons/FolderSpecial';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import NavigationItem from './NavigationItem';
import { Link } from "react-router-dom";
import { getUserRoles, ROLE_ADMIN, removeCredentials } from '../common/security';

const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },
    backgroundColor: 'white',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

function Navigation(props)  {

    const isOpen = false;
    const { classes, theme } = props;

    const roles = getUserRoles();

    if (!roles) {
      return <span></span>;
    }

    const drawer = (
      <div>
        <div className={classes.toolbar}>
          <img alt="logo" src="/ASI.png" width="223px"/>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard">
            <ListItem button key='dashboard'>
                <ListItemIcon><HomeIcon/></ListItemIcon>
                <ListItemText primary='Acceuil' />
            </ListItem>
          </Link>
          { roles === ROLE_ADMIN && 
            <NavigationItem
              key="provider"
              label="Fournisseur"
              icon={<FolderSpecial/>}
              addUrl="/providers/create"
              listUrl="/providers"
            />
          }
          <NavigationItem
            key="patient"
            label="Patient"
            icon={<Person/>}
            addUrl="/patients/create"
            listUrl="/patients"
          />
          { roles === ROLE_ADMIN && 
            <Link to="/parameter" onClick={() => {}}>
                <ListItem button key='parametre'>
                    <ListItemIcon><Settings/></ListItemIcon>
                    <ListItemText primary={'Paramétres'} />
                </ListItem>
            </Link>  
          }
        <Link to="/login" onClick={() => {
          removeCredentials();
        }}>
            <ListItem button key='logout'>
                <ListItemIcon><ExitToApp/></ListItemIcon>
                <ListItemText primary={'Deconnection'} />
            </ListItem>
        </Link>  
        </List>
      </div>
    );

    return (
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              container={props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={isOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
    );
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(Navigation);
