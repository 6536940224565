import React,  { useState} from 'react';
import Client from '../common/clients';

const submit = async (data, history) => {
  const client = new Client();

  try {
    if (!(data = await client.login(data.username, data.password))) {
      return false;
    }
  } catch (err) {
    return false;
  }
  
  if (!data.token) {
    return false;
  }

  const storage = window.sessionStorage;

  storage.setItem('token', data.token);
  storage.setItem('roles', data.data.roles);

  return history.push('/');
}


const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100vh",
    backgroundColor: 'aliceblue',
  },
  login: {
    width: '500px',
    height: '300px',
    verticalAlign: 'middle',
    border: '0.1px solid',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  form: {
    display: 'flex',
    height: '100px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error : {
    color: 'red'
  }
}

export default (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [displayError] = useState(false);
  const {history} = props;

  return (
    <div style={styles.root}>
      <div style={styles.login}>
        <div style={styles.container}>
          <img alt="logo" src="/ASI.png" width="223px"/>
        </div>
        <div style={styles.container}>
          <h3>ASI Manager</h3>
        </div>
        { displayError && 
          <div style={styles.error}>
            Identifiant ou mot de passe invalide
          </div>
        }
        <div style={styles.container}>
          <form style={styles.form}>
            <div>Identifiant :</div>
            <div>
              <input type="text" name="username" value={username} autoComplete="username" onChange={e => setUsername(e.target.value)} /><br/>
            </div>
            <div>Mot de passe :</div>
            <div>
                <input type="password" name="password" autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} /><br/>
            </div>
            <div>
              <input type="button" value="Valider" onClick={() => { submit({username, password}, history); }} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

