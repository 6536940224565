import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core';
import List from './PatientTransmission/List';
import Form from './PatientTransmission/Form';
import Client from '../common/clients';


export default ({patientId}) => {
  const [transmissionData, setTransmissionData] = useState();
  const client = new Client();

  useEffect(() => {
    client.getPatientTransmission(patientId).then((data) => setTransmissionData(data));
  }, []);

  const handleSubmit = (data) => {
    setTransmissionData([...transmissionData,data]);
  };

  const handleDelete = (id) => {
    client.deletePatientTransmission(id).then(() => 
      client.getPatientTransmission(patientId).then((data) => setTransmissionData(data))
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{marginBottom: '100px'}}>
          <Form patientId={patientId} handleSubmit={handleSubmit}/>
      </Grid>
      <Grid item xs={12}>
        <List data={transmissionData} handleDelete={handleDelete}/>
      </Grid>
    </Grid>
  )
}
