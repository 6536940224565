import React, {useState, useEffect} from 'react';
import {Button, Grid} from "@material-ui/core";
import Client from "../common/clients";
import RichTextEditor from "react-rte";

const PatientPrescription = ({patientId}) => {

    const [activity, setActivity] = useState(
        RichTextEditor.createEmptyValue()
    );
    const [way, setWay] = useState(RichTextEditor.createEmptyValue());
    const [injectionWay, setInjectionWay] = useState(RichTextEditor.createEmptyValue());

    const client = new Client();

    useEffect(() => {
        client.getPatientInstruction(patientId).then((data) => {
            if (data.activity) {
                setActivity(RichTextEditor.createValueFromString(data.activity, 'html'));
            }

            if (data.way) {
                setWay(RichTextEditor.createValueFromString(data.way, 'html'));
            }

            if (data.injectionWay) {
                setInjectionWay(RichTextEditor.createValueFromString(data.injectionWay, 'html'));
            }
        });
    }, [])

    const submit = () => {
        client
            .createPatientInstruction({
                activity: activity.toString("html").replace(/\n/gi, ""),
                way: way.toString("html").replace(/\n/gi, ""),
                injectionWay: injectionWay.toString("html").replace(/\n/gi, ""),
            }, patientId)
            .then((response) => {
                return response
            })
            .then(() => {
                alert("Prescription enregistrée");
            });
    }


    return (
        <Grid container spacing={24}>
            <Grid item xs={12}>
                Soin réalisé
                <RichTextEditor
                    editorStyle={{
                        height: "250px",
                    }}
                    value={activity}
                    onChange={(value) => setActivity(value)}
                    toolbarConfig={{
                        display: ["INLINE_STYLE_BUTTONS"],
                        INLINE_STYLE_BUTTONS: [
                            { label: "Bold", style: "BOLD", className: "custom-css-class" },
                            { label: "Italic", style: "ITALIC" },
                            { label: "Underline", style: "UNDERLINE" },
                        ],
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                Mode d'admission
                <RichTextEditor
                    editorStyle={{
                        height: "250px",
                    }}
                    value={way}
                    onChange={(value) => setWay(value)}
                    toolbarConfig={{
                        display: ["INLINE_STYLE_BUTTONS"],
                        INLINE_STYLE_BUTTONS: [
                            { label: "Bold", style: "BOLD", className: "custom-css-class" },
                            { label: "Italic", style: "ITALIC" },
                            { label: "Underline", style: "UNDERLINE" },
                        ],
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                Type de voie d'abord
                <RichTextEditor
                    editorStyle={{
                        height: "250px",
                    }}
                    value={injectionWay}
                    onChange={(value) => setInjectionWay(value)}
                    toolbarConfig={{
                        display: ["INLINE_STYLE_BUTTONS"],
                        INLINE_STYLE_BUTTONS: [
                            { label: "Bold", style: "BOLD", className: "custom-css-class" },
                            { label: "Italic", style: "ITALIC" },
                            { label: "Underline", style: "UNDERLINE" },
                        ],
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <Button
                    onClick={submit}
                    variant="contained"
                    color="primary"
                    style={{ margin: "center", paddingTop: "10px" }}
                >
                    Valider
                </Button>
            </Grid>
        </Grid>
    );
}

export default PatientPrescription;
