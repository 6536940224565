import React, { useEffect } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import PatientTab from '../components/PatientTab';
import Client from '../common/clients';


const styles = (theme) => ({
});


const Patient =  (props) => {

  const { classes, match } = props;
  
  const [patient, setPatient] = React.useState();
  const client = new Client();

  useEffect(() => {
      client.getPatient(match.params.id).then(data => setPatient(data));
  }, []);

  return (
    <div>
      <h4 style={{textAlign: 'center', color: 'white'}}>Patient</h4>
      <Grid container className={classes.tab}>
        <Grid item xs={12}>
          <PatientTab patient={patient}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles, {withTheme: true})(Patient);

