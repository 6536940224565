import React from 'react'
import {Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import config from '../../config.json';

const List = ({data}) => {

  if (!data) {
    return <span></span>;
  }

  return (
    <Table>
      <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Fichier</TableCell>
        <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <TableRow key={`${item.id}_row`}>
            <TableCell key={`${item.id}_date`} >{item.createdAt}</TableCell>
            <TableCell key={`${item.id}_fichier`} >
              <a href={`${config.url}/${item.path}`}>{item.path}</a>
            </TableCell>
            <TableCell key={`${item.id}_action`} >{item.target}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default List;
