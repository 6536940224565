import React, {useRef, useState, useEffect} from 'react';
import { Input, Button } from '@material-ui/core';
import Client from '../common/clients';

const styles = {
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  formElement: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex'
  },
  input: {
    marginLeft: '10px',
  },
  label: {
    width: '150px',
    position: 'relative',
    textAlign: 'start',
    padding: '5px'
  }
}

const Parameter = () => {

  const formRef = useRef(null);
  const [values, setValues] = useState(null);
  const  [display, setDiplay] = useState(false);

  useEffect(() => {
    const client = new Client();
    client.getParameter().then(data => setValues(data));
  }, [])

  const data = {
    'weight' : 'Poids',
    'tas' : 'TAS',
    'tad': 'TAD',
    'pulse': 'Pouls',
    'temperature': 'Temperature',
    'sat': 'Saturation',
  }
  const form = Object.keys(data).map(key => {
  if (!values) {
    return null;
  }
  const min = (values[`min_${key}`]) ? values[`min_${key}`] : '';
  const max = (values[`max_${key}`]) ? values[`max_${key}`] : '';
  return (<div style={styles.formElement} key={key}>
    
    <div style={styles.label}>{data[key]}</div>
    <Input style={styles.input} type="text" defaultValue={min} name={`parameter[min_${key}]`} placeholder="min"/>
    <Input style={styles.input} type="text"  defaultValue={max} placeholder="max" name={`parameter[max_${key}]`}/>
    </div>
    )
  });

  const submit = (formData) => {
    const client = new Client();

    client.saveParameter(formData);
    setDiplay(true);
  }

  return (
    <div style={styles.root}>
      <h3>Parameter</h3>
      {display && <div style={{color: 'green', marginBottom: '10px'}}>Enregistrer avec succés </div>}
      {values && <form ref={formRef}>
        {form}
        <div style={styles.formElement}>
          <Button variant="contained" color="primary" component="span" onClick={() =>  {
              const form = new FormData(formRef.current)
              submit(form);
             }}>
              Enregistrer
          </Button>
        </div>
      </form>
      }
    </div> 
  )
};

export default Parameter;