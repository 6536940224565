import React from "react";
import { withStyles, AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import PatientData from "../components/PatientData";
import PatientTransmission from "../components/PatientTransmission";
import PatientExport from "../components/PatientExport";
import PatientForm from "../containers/PatientForm";
import PatientImage from "../components/PatientImage";
import { getUserRoles, ROLE_ADMIN } from "../common/security";
import PatientDiseaseHistory from "./PatientDiseaseHistory";
import PatientPrescription from "./PatientPrescription";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({});

const PatientTab = (props) => {
  const { classes, patient } = props;

  const [value, setValue] = React.useState( "info");

  const role = getUserRoles();

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab value="info" label={"Info"} />
          <Tab value="mesure" label={"Mesure"} />
          <Tab value="transmission" label={"Transmission"} />
          <Tab value="prescription" label={"Prescription"} />
          {patient && patient.category === "IV" && (
            <Tab value="maladie" label={"Synthése"} />
          )}
          <Tab value="image" label={"Image"} />
          {role === ROLE_ADMIN && <Tab value="export" label={"Export"} />}
        </Tabs>
      </AppBar>
      {value === "info" && patient && (
        <TabContainer>
          <PatientForm patient={patient} />
        </TabContainer>
      )}
      {value === "mesure" && (
        <TabContainer>
          <PatientData patientId={patient.id} type="weight" unit="kg" />
        </TabContainer>
      )}
      {value === "transmission" && (
        <TabContainer>
          <PatientTransmission patientId={patient.id} />
        </TabContainer>
      )}
      {value === "maladie" && (
        <TabContainer>
          <PatientDiseaseHistory patientId={patient.id} />
        </TabContainer>
      )}
      {value === "prescription" && (
          <TabContainer>
            <PatientPrescription patientId={patient.id} />
          </TabContainer>
      )}
      {value === "image" && (
        <TabContainer>
          <PatientImage patientId={patient.id} />
        </TabContainer>
      )}
      {role === ROLE_ADMIN && value === "export" && (
        <TabContainer>
          <PatientExport patientId={patient.id} />
        </TabContainer>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(PatientTab);
